import React, { useState } from "react";
import "./HeroSection.css";

const HeroSection = () => {
  const [message, setMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  // Handlers for button clicks
  const handleYesClick = () => {
    setMessage("🌱 Your plants thank you for the love! Keep up the great work!");
    setIsVisible(true);
  };

  const handleNoClick = () => {
    setMessage("😢 Don't forget to give your plants some water. They need you!");
    setIsVisible(true);
  };

  return (
    <div
      className="hero-container"
      style={{
        backgroundImage: `url('/img/backgroundmain4.webp')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="overlay"></div>
      <div className="hero-text">
        <h1 className="animated-title">Welcome to HerbFarmer's Digital Garden</h1>
        <p className="animated-subtitle">Where a plant lover meets innovation</p>

        <p className="plant-prompt">Did you water your plant today?</p>
        <div className="button-group">
          <button className="yes-button" onClick={handleYesClick}>
            Yes 🌿
          </button>
          <button className="no-button" onClick={handleNoClick}>
            No 😞
          </button>
        </div>

        {/* Pre-allocated space for the response message */}
        <div className="response-message-container">
          <p className={`response-message ${isVisible ? "visible" : ""}`}>
            {message || "\u00A0" /* Non-breaking space to maintain height */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
