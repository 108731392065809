// src/components/AboutMe.js
import React from 'react';
import './AboutMe.css';

const AboutMe = () => {
  return (
    <div className="about-container" id="about">
      <div className="about-image">
        <img src={`${process.env.PUBLIC_URL}/img/farmerboy.webp`} alt="HerbFarmer" />
      </div>
      <div className="about-text">
        <h2>About Me</h2>
        <p>
          Hey everyone! I'm HerbFarmer, and this is my spot on the web where I'm diving into the world of IT.
          Whether it's figuring out how servers work or starting with some basic coding, I'm all about learning as I go.
          This site isn't just about showing off what I know; it's also a place where I share what I'm learning along the way.
        </p>
        <p>
          If you're curious about my journey into the tech world, stick around, check out what I've been working on, and let's learn together!
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
