// MyProjects.js
import React from "react";
import "./MyProjects.css";

const projects = [
  {
    title: "Website",
    image: "/img/website1.webp",
    description: "Building this website has been a wild ride—tons of ups and downs, but I learned a lot along the way. I’ve put in a lot of effort, and it’s just the beginning! If you want to see what’s coming next, hit the button below to check out the roadmap. I’ve got a bunch of cool features planned, and I’d love to hear your thoughts as I keep leveling it up!",
    link: `${window.location.origin}/project1`,
    buttonText: "More about my Website...",
  },
  {
    title: "Tailwind CSS",
    image: "/img/farmerboy3.webp",
    description: "I’ve been testing out different frameworks and libraries while working on a new version of my website, and decided to give Tailwind CSS a shot. It’s a huge upgrade from writing raw HTML and CSS, thanks to its utility-first approach. You can easily style elements by adding simple classes directly in the HTML. Click the button below to see a quick demo I built—it came together much faster than I expected, and the responsiveness is on point. Check it out!",
    link: `${window.location.origin}/tailwindcss/tailwindcss.html`,
    buttonText: "Tailwind Example...",
  },
  {
    title: "Knowledge",
    image: "/img/knowledge4.webp",
    description: "Welcome to the Knowledge Zone! Here, I share my thoughts and insights on various topics that have caught my interest. My goal is to build a wiki that not only helps me organize my ideas but also inspires others to dive into IT projects and see that it can be much easier than it seems—just take the first step! Click below to explore and start your journey!",
    link: `${window.location.origin}/project3`,
    buttonText: "Expand Knowledge...",
  },
];

const MyProjects = () => {
  return (
    <section id="projects" className="projects-section">
      <div className="containerProjects">
        <h2 className="section-title">My Projects</h2>
        <div className="projects-grid">
          {projects.map((project, index) => (
            <div key={index} className="project-card">
              <h3 className="project-title">{project.title}</h3>
              <img
                src={project.image}
                alt={project.title}
                className="project-image"
              />
              <p className="project-description">{project.description}</p>
              <button
                onClick={() => window.open(project.link, "_blank")}
                className="project-button gradient-button"
              >
                {project.buttonText}
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MyProjects;
