// App.js
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import AboutMe from "./components/AboutMe";
import MyProjects from "./components/MyProjects";
import Ideas from "./components/Ideas";
import Footer from "./components/Footer";
import Project1 from "./projects/Project1";
import Project3 from "./projects/Project3";
import "./App.css";

function App() {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 10);
  }, [location.pathname]);

  const isProjectPage = location.pathname === "/project1" || 
                        location.pathname === "/project3" || 
                        location.pathname === "/tailwindcss/tailwindcss.html";

  return (
    <div className="App">
      {!isProjectPage && <Navbar />}
      {!isProjectPage && <HeroSection />}
      {!isProjectPage && <AboutMe />}
      {!isProjectPage && <MyProjects />}
      {!isProjectPage && <Ideas />}
      {!isProjectPage && <Footer />}
      <Routes>
        <Route path="/project1" element={<Project1 />} />
        <Route path="/project3" element={<Project3 />} />
        {/* Direct link to Tailwind HTML */}
        <Route
          path="/tailwindcss/tailwindcss.html"
          element={<iframe src="/tailwindcss/tailwindcss.html" style={{ width: '100%', height: '100vh', border: 'none' }} />}
        />
      </Routes>
    </div>
  );
}

export default App;
