// Project1.js
import React from "react";

const Project1 = () => {
  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>Work in Progress</h1>
      <p>This page is currently under construction. Stay tuned for updates!</p>
    </div>
  );
};

export default Project1;
