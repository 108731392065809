// Ideas.js
import React from "react";
import "./Ideas.css";

const Ideas = () => {
  return (
    <div id="ideas" className="ideas-section">
      {/* Idea 1 */}
      <div className="idea-card">
        <div className="idea-content">
          <h3>Testing the Waters: Attacking My Own System</h3>
          <p>
            So, I had this idea to dive into my own system and see where it's vulnerable. It's like a challenge I set for myself – breaking into my own digital fortress to find the weak spots. By playing both attacker and defender, I learn how to make it stronger.
          </p>
          <p>
            The best part? It’s a great way to showcase my skills. Imagine saying, "I hacked my own system to make it stronger" during an interview. It’s like having a secret weapon to impress potential employers or clients.
          </p>
        </div>
        <div className="idea-image">
          <img src={`${process.env.PUBLIC_URL}/img/farmerboy2.webp`} alt="Attacking System" />
        </div>
      </div>

      {/* Idea 2 */}
      <div className="idea-card">
        <div className="idea-content">
          <h3>Integrate New Tools and Technologies</h3>
          <p>
          I started off using raw HTML, CSS, and a bit of Java, which gave me a good foundation. But as I kept learning, I realized how quickly tech is evolving, so I picked up React to make my projects more interactive and modern. 
          </p>
          <p>
          Now, I’m working on integrating even more new tools and technologies into my setup. It’s all about getting hands-on experience and making the most out of what’s available in IT today. It’s been exciting seeing what’s possible, and I’m ready to push my skills further and keep up with the latest trends.
          </p>
        </div>
        <div className="idea-image">
          <img src={`${process.env.PUBLIC_URL}/img/farmerboy4.webp`} alt="Online Shop" />
        </div>
      </div>
    </div>
  );
};

export default Ideas;
